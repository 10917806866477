import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Sparkling Clean Homes
			</title>
			<meta name={"description"} content={"Повертаємо блиск у ваш дім"} />
			<meta property={"og:title"} content={"Sparkling Clean Homes"} />
			<meta property={"og:description"} content={"Повертаємо блиск у ваш дім"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/pngtree-abstract-hexagonal-background-icon-png-image_6276075.png?v=2024-06-19T11:48:50.381Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11:44:30.617Z"
						display="block"
						max-width="100%"
						width="350px"
						border-radius="30px"
						srcSet="https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11%3A44%3A30.617Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11%3A44%3A30.617Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11%3A44%3A30.617Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11%3A44%3A30.617Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11%3A44%3A30.617Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11%3A44%3A30.617Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-2.jpg?v=2024-06-19T11%3A44%3A30.617Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Ми в Sparkling Clean Homes віримо, що чистий дім – це щасливий дім.
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						Наша віддана команда професійних прибиральників тут, щоб перетворити ваше житлове приміщення на незаймане святилище. Незалежно від того, чи потрібне вам регулярне технічне обслуговування чи глибоке очищення, ми допоможемо вам. Дозвольте нам подбати про прибирання, щоб ви могли більше насолоджуватися найважливішим.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Про нас
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Sparkling Clean Homes було засновано з єдиною місією: надавати виняткові послуги з прибирання, завдяки яким ваш дім буде блискучим, а ваші думки заспокоєні. Ми розуміємо, що кожен будинок унікальний, і наш підхід розроблено відповідно до ваших конкретних потреб. Наша команда досвідчених прибиральників використовує екологічно чисті продукти та передові технології для забезпечення найвищих стандартів чистоти та гігієни. Ми пишаємося своєю увагою до деталей і прагненням до досконалості, що робить нас кращим вибором для домовласників, які шукають надійні та ефективні рішення для прибирання.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші переваги та послуги
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Від звичайного прибирання до спеціалізованого глибокого прибирання, Sparkling Clean Homes пропонує широкий спектр послуг, спрямованих на те, щоб ваш дім виглядав якнайкраще. Наші гнучкі варіанти планування та індивідуальні плани прибирання означають, що ви можете вибрати рівень обслуговування, який найкраще відповідає вашому стилю життя та бюджету. Незалежно від того, чи це одноразове очищення, чи регулярне обслуговування, наша команда готова щоразу досягати видатних результатів.
				</Text>
				<Link
					href="/services"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-indigo"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Послуги
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Наша команда складається з висококваліфікованих і досвідчених прибиральників, які знають, як впоратися навіть із найскладнішими викликами прибирання.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Ми пишаємося нашим ретельним підходом, гарантуючи, що кожен куточок вашого дому буде ретельно очищений і продезінфікований.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Ми гарантуємо задоволення своєю роботою. Якщо ви не задоволені нашими послугами, ми виправимо це.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 20px 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Поверніть блиск у свій дім!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="normal 400 25px/1.5 --fontFamily-serifGaramond" color="#333b41">
							Відкрийте для себе радість чистого дому з Sparkling Clean Homes. Дозвольте нам виконати прибирання, щоб ви могли насолоджуватися свіжим, чистим житловим простором. Зв’яжіться з нами зараз, щоб запланувати перше обслуговування та відчути різницю, яку може зробити професійне прибирання.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11:44:30.609Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11%3A44%3A30.609Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11%3A44%3A30.609Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11%3A44%3A30.609Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11%3A44%3A30.609Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11%3A44%3A30.609Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11%3A44%3A30.609Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad8f8d4a0c002030a583/images/1-3.jpg?v=2024-06-19T11%3A44%3A30.609Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});